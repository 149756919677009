/**
 * Writes a message to console.log() and returns its value.
 * @customfunction LOG LOG
 * @param {string} message - The message to write.
 * @returns {string} - The message.
 */
export function logMessage(message: string): string {
    try{
        console.log(message);
    } catch (error) {
        console.error(error);
    }
    return message;
}
CustomFunctions.associate("LOG", logMessage);